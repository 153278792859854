import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SearchResource } from '@app/@shared/interface/search-resource';
import { CartService, ProductType } from '@app/routes/cart/models/cart/cart.service';
import { Logger } from '@core';
import { environment } from '@env/environment';
import { Settings } from '@shared/models/settings/settings.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
const logger = new Logger('LandingComponent');

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {
  selected: SearchResource;
  settings$: Observable<Settings>;
  year: any = moment().format('Y');
  environment = environment;

  constructor(private cartService: CartService, private router: Router) {}

  getAddressFromGeolocation() {
    logger.info('getAddressFromGeolocation()');
  }

  addressSelected(selected: SearchResource) {
    this.selected = selected;
  }

  addToCart() {
    this.cartService.add(ProductType.REPORT, this.selected.id);
    this.router.navigate([`/cart`]);
  }
}
