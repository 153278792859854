<div class="top-actions">
  <div class="address">
    <app-search
      (change)="addressSelected($event)"
      context="landing"
      label="Wpisz nr ewidencyjny lub ulicę, nr i pełną nazwę gminy"
    >
    </app-search>
    <!-- <button (click)="addToCart()" [disabled]="!selected" class="order" color="primary" mat-flat-button>
      ZAMÓW RAPORT
    </button> -->
  </div>

  <div class="find">
    <span class="or">lub</span>
    <a class="overview" color="primary" mat-button href="https://mapa.osiadanieterenu.pl/home">
      ZNAJDŹ ADRES NA MAPIE
    </a>
  </div>
</div>
