import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { LandingComponent } from './landing/landing.component';
import { StartRoutingModule } from './start-routing.module';

@NgModule({
  declarations: [LandingComponent],
  imports: [CommonModule, StartRoutingModule, SharedModule],
})
export class StartModule {}
